import React, { useState } from "react";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Col, Row, Button, Grid, Skeleton, Typography } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

import breakpoints from "../../theme/breakpoints";
import { surveyNames } from "../sections/cover";

const { md } = breakpoints;
const { useBreakpoint } = Grid;
const { Link: ExternalLink } = Typography;

const MenuSkeleton = styled(Skeleton.Button)`
  .ant-skeleton-button {
    width: 2rem;
    vertical-align: middle;
  }
`;

const Container = styled.nav`
  padding: 0 2.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  background-color: var(--color-white);
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.12);

  @media screen and (max-width: ${md}px) {
    padding: 0 1.25rem;
  }

  .ant {
    &icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-col {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-height: ${({ isOpen }) => (isOpen ? "100vh" : "4rem")};
      height: 100%;
      width: 100%;

      @media screen and (max-width: ${md}px) {
        align-items: flex-start;
      }
    }
  }

  a:not(.ant-btn) {
    color: var(--color-grey-darker);

    :hover {
      color: var(--color-primary);
    }
  }
`;
const Inner = styled.div`
  display: flex;
  max-width: calc(100% - 3rem);
  width: 100%;

  @media screen and (max-width: ${md}px) {
    flex-direction: column;
    max-width: calc(100% - 3rem);
  }
`;
const Title = styled(Link)`
  margin-right: 2rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: ${md}px) {
    margin-right: 0;
  }
`;
const SkeletonContainer = styled.div`
  width: 100%;

  .ant-skeleton {
    width: 12.5rem;
    margin-right: 2rem;

    &-content {
      height: 4rem;
      vertical-align: middle;
    }

    &-title {
      margin: 0;
    }
  }
`;
const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 0 auto;
    padding: 0;
    width: 100%;
    list-style: none;

    @media screen and (max-width: ${md}px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2.5rem;
    }

    li {
      .ant-skeleton {
        &-title {
          margin: auto;
        }

        &-button {
          vertical-align: middle;
        }
      }

      @media screen and (min-width: ${md + 1}px) {
        :not(:last-child) {
          margin-right: 1rem;
        }
      }

      @media screen and (max-width: ${md}px) {
        line-height: 3rem;

        .ant-btn {
          padding: 0.5rem 0;
          border: 0;
        }
      }

      a:not(.ant-btn) {
        padding-bottom: 0.375rem;
        border-bottom: 0.125rem solid transparent;
        transition: all 0.3s ease-in-out;

        :hover,
        &.active {
          border-bottom-color: var(--color-primary);
        }

        &.active {
          color: var(--color-primary);
        }
      }
    }
  }
`;
const MenuButton = styled(Button)`
  margin: 1rem 0 1rem 1rem;
`;

const Header = ({ notFound }) => {
  const { page, tools = [] } = useSelector(({ app }) => app);
  const { title, key } = { ...page };

  const { push } = useHistory();
  const { sm, lg } = useBreakpoint();

  const [visible, setVisible] = useState(false);
  const Icon = visible ? CloseOutlined : MenuOutlined;

  const baseUrl = "/";
  const buttons = tools
    .map(({ key }) => ({
      name: surveyNames?.[key] || "",
      to: `/${key}`,
    }))
    .reverse();

  return (
    <Container isOpen={visible}>
      <Row>
        <Col span={24}>
          <Inner>
            {notFound ? (
              <ExternalLink href="https://чего-хочет-город.рф/">
                Чего хочет город
              </ExternalLink>
            ) : title ? (
              <Title to={baseUrl} onClick={() => setVisible(false)}>
                {title}
              </Title>
            ) : (
              <SkeletonContainer>
                <Skeleton paragraph={false} active />
              </SkeletonContainer>
            )}
            {!notFound && ((!lg && visible) || lg) && (
              <LinksContainer isOpen={visible}>
                <ul>
                  {key ? (
                    buttons.map(({ name, to }, i) => (
                      <li key={i}>
                        <Button
                          size="large"
                          type={sm ? "default" : "link"}
                          block={!sm}
                          onClick={() => {
                            push(to);
                            visible && setVisible(false);
                          }}
                        >
                          {name}
                        </Button>
                      </li>
                    ))
                  ) : (
                    <li>
                      <Skeleton.Button size="large" active />
                    </li>
                  )}
                </ul>
              </LinksContainer>
            )}
          </Inner>
          {!notFound &&
            !lg &&
            (key ? (
              <MenuButton
                type="text"
                icon={<Icon />}
                onClick={() => setVisible(!visible)}
              />
            ) : (
              <MenuSkeleton active />
            ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Header;

import React from "react";
import { Checkbox, Form, Radio } from "antd";
import styled from "styled-components";

import OtherField from "./other-field";
import { isChecked } from "../../utils";

const Container = styled.div`
  padding-top: 0.5rem;

  .ant {
    &-radio {
      top: -0.125rem;
    }

    &-radio,
    &-checkbox {
      &-wrapper {
        display: inline-block;
        margin-left: 1.5rem;
        padding-bottom: 0.5rem;
        width: calc(100% - 2rem);
        text-indent: -1.5rem;
      }
    }
  }
`;

const Option = styled.span`
  :not(:only-child) {
    margin-right: 0.5rem;
  }
`;

const ControlGroup = ({ name, type, options, value: initialValue }) => {
  const Component = type === "radio" ? Radio : Checkbox;
  const handleOtherValue = (answers) =>
    answers.filter(({ type }) => type === "plain")?.[0]?.value;
  const otherValue = handleOtherValue(options);
  const otherField = `${name}_${otherValue}`;
  const rules = otherValue && [
    ({ getFieldValue }) => ({
      validator: (rule, value) => {
        const isOther = isChecked(value, otherValue);
        const isNotValid =
          getFieldValue(otherField) === undefined ||
          getFieldValue(otherField) === "";

        return isOther && isNotValid
          ? Promise.reject("Заполните поле «Другое»!")
          : Promise.resolve();
      },
      validateTrigger: "onSubmit",
    }),
  ];

  return (
    <Container>
      <Form.Item
        {...{ name, rules }}
        dependencies={otherValue && [otherField]}
        noStyle
      >
        <Component.Group>
          {options?.map(({ name: label, value, type }, i) => (
            <Component key={i} {...{ value }}>
              <Option>{label}</Option>
              {type === "plain" && (
                <OtherField
                  {...{ name }}
                  otherValue={value}
                  disabled={!isChecked(initialValue, value)}
                />
              )}
            </Component>
          ))}
        </Component.Group>
      </Form.Item>
    </Container>
  );
};

export default ControlGroup;

const breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export default breakpoints;

const capitalizeFirstLetter = ([first, ...rest]) =>
  first.toUpperCase() + rest.join("");

const handleMediaRule = (breakpoint, min) =>
  `@media screen and (${min ? "min" : "max"}-width: ${
    breakpoint - (min ? 0 : 0.02)
  }px)`;

const handleMediaRules = (breakpoints) => {
  const rules = Object.keys(breakpoints).flatMap((key) => {
    const { [key]: value } = { ...breakpoints };
    const captalizedKey = capitalizeFirstLetter(key);

    return [
      { [`media${captalizedKey}`]: handleMediaRule(value) },
      { [`media${captalizedKey}Up`]: handleMediaRule(value, true) },
    ];
  });

  return Object.assign({}, ...rules);
};

export const mediaRules = handleMediaRules(breakpoints);

import React from "react";
import styled, { css } from "styled-components/macro";
import ReactMarkdown from "react-markdown";
import { Col, Row, Skeleton, Space, Typography } from "antd";

import breakpoints from "../../theme/breakpoints";
import ButtonGroup from "../buttons/button-group";
import Container from "../layout/container";

const { Title, Paragraph, Link } = Typography;
const { sm, lg } = breakpoints;

const cover = css`
  padding: 8rem 0 6rem;
  min-height: 75vh;
  background-image: url(${({ bgImage }) => bgImage || ""});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @supports not (-webkit-touch-callout: none) {
    background-attachment: fixed;
  }

  @media screen and (max-width: ${lg}px) {
    padding: 6rem 0 4rem;
  }

  @media screen and (max-width: ${sm}px) {
    margin-bottom: 2rem;
    padding-bottom: 3rem;
  }

  ::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  h2.ant-typography,
  .ant-typography {
    &,
    h2 {
      color: var(--color-white);
    }
  }

  .ant-typography p {
    font-weight: 400;
  }
`;

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  padding: 12rem 0 3rem;
  min-height: 30rem;

  @media screen and (max-width: ${lg}px) {
    margin-bottom: 2rem;
    padding: 9rem 0 2rem;
  }

  @media screen and (max-width: ${sm}px) {
    margin-bottom: 1rem;
    padding: 6rem 0 1rem;
  }

  h2.ant-typography,
  .ant-typography h2 {
    color: var(--color-primary);
  }

  .ant-skeleton {
    margin-bottom: 1rem;
  }

  ${({ bgImage }) => bgImage && cover}
`;

export const surveyNames = {
  "well-survey": "Потребности сотрудников",
  "stress-survey": "Стресс на работе",
};

const Cover = ({ title, lead, photo, slug, tools = [] }) => {
  const buttons = tools
    .map(({ key }) => ({
      name: surveyNames?.[key] || "",
      to: `/${key}`,
    }))
    .reverse();

  return (
    <Wrapper id="survey" bgImage={photo}>
      <Container>
        <Space direction="vertical">
          {title ? <Title>{title}</Title> : <Skeleton active />}
          <Space size={32} direction="vertical">
            <Row>
              {lead && (
                <Col span={24} md={20} lg={16}>
                  <Paragraph>
                    <ReactMarkdown
                      renderers={{
                        link: ({ children, ...props }) => (
                          <Link
                            {...props}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {children || ""}
                          </Link>
                        ),
                      }}
                    >
                      {lead}
                    </ReactMarkdown>
                  </Paragraph>
                </Col>
              )}
            </Row>
            {slug ? (
              <ButtonGroup {...{ buttons }} />
            ) : (
              <Skeleton.Button size="large" active />
            )}
          </Space>
        </Space>
      </Container>
    </Wrapper>
  );
};

export default Cover;

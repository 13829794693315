import React from "react";
import { Empty, Steps } from "antd";

const { Step } = Steps;

const StepsSidebar = ({ steps, current }) =>
  steps?.length > 0 ? (
    <Steps direction="vertical" {...{ current }}>
      {steps.map(({ title }, i) => (
        <Step key={i} {...{ title }} />
      ))}
    </Steps>
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет шагов" />
  );

export default StepsSidebar;

import { cloneElement, useState, useEffect } from "react";

const VKLogin = ({ apiId, callback, settings, render }) => {
  const [state, setState] = useState({
    isSdkLoaded: false,
    isProcessing: false,
  });
  const { isSdkLoaded, isProcessing } = state;

  const setLoaded = () =>
    setState((state) => ({ ...state, isSdkLoaded: true }));

  const loadSdkAsynchronously = () => {
    const el = document.createElement("script");
    el.type = "text/javascript";
    el.src = "https://vk.com/js/api/openapi.js?168";
    el.async = true;
    el.id = "vk-jssdk";
    document.getElementsByTagName("head")[0].appendChild(el);
  };

  const checkLoginState = (response) => {
    setState((state) => ({ ...state, isProcessing: false }));

    if (callback) {
      callback(response);
    }
  };

  const onClick = () => {
    if (!isSdkLoaded || isProcessing) {
      return;
    }
    setState((state) => ({ ...state, isProcessing: true }));
    window.VK.Auth.login(checkLoginState, settings);
  };

  useEffect(() => {
    const setVkAsyncInit = () => {
      window.vkAsyncInit = () => {
        window.VK.init({ apiId });
        setLoaded();
      };
    };

    if (document.getElementById("vk-jssdk")) {
      setLoaded();
    }
    setVkAsyncInit();
    loadSdkAsynchronously();
  }, [apiId]);

  if (!render) {
    throw new Error("ReactVkontakteLogin requires a render prop to render");
  }

  const propsForRender = {
    onClick,
    loading: isProcessing,
    disabled: !isSdkLoaded,
  };

  return cloneElement(render(propsForRender));
};

export default VKLogin;

import React from "react";
import { useSelector } from "react-redux";
import { Space } from "antd";
import styled from "styled-components/macro";

import Meta from "../components/layout/meta";
import Cover from "../components/sections/cover";
import Description from "../components/sections/initiators";

const Container = styled.div`
  margin-bottom: 4rem;
`;

const Main = () => {
  const { page, tools } = useSelector(({ app }) => app);
  const { title, lead, about, initiators, photo, key: slug } = {
    ...page,
  };

  return (
    <Container>
      <Meta {...{ page }} />
      <Cover {...{ title, lead, photo, slug, tools }} />
      <Space direction="vertical" size={64}>
        <Description title="О проекте" content={about} />
        {initiators && (
          <Description title="Инициаторы проекта" content={initiators} />
        )}
      </Space>
    </Container>
  );
};

export default Main;

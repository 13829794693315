import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { YMInitializer } from "react-yandex-metrika";

import "./index.less";
import reducers from "./reducers";
import App from "./pages/app";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";

const counter = process.env.REACT_APP_YANDEX_METRIKA;
const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
  <>
    {counter && (
      <YMInitializer
        accounts={[parseInt(counter)]}
        options={{ webvisor: true }}
        version="2"
      />
    )}
    <Provider {...{ store }}>
      <Router>
        <App />
      </Router>
    </Provider>
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

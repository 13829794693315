import React from "react";
import { Row, Col } from "antd";
import styled, { css } from "styled-components/macro";

import breakpoints from "../../theme/breakpoints";

const { sm, md, lg, xl } = breakpoints;
const narrowStyles = css`
  @media screen and (max-width: ${xl}px) {
    max-width: ${lg}px;
  }

  @media screen and (max-width: ${lg}px) {
    max-width: ${md}px;
  }

  @media screen and (max-width: ${md}px) {
    padding: 0 1.25rem;
    max-width: ${sm}px;
  }

  @media screen and (max-width: ${sm}px) {
    max-width: 100%;
  }
`;

const Wrapper = styled(({ wide, ...rest }) => <Row {...rest} />)`
  margin: 0 auto;
  padding: ${({ wide }) => (wide ? 0 : "0 2.5rem")};
  max-width: ${({ wide }) => (wide ? "100%" : `${xl}px`)};
  width: 100%;
  ${({ wide }) => !wide && narrowStyles};
`;

export const SectionContainer = styled.div`
  padding: 4rem 0;
`;
export const PageContainer = styled.div`
  padding: 8rem 0 6rem;
`;

const Container = ({ wide, children }) => (
  <Wrapper {...{ wide }}>
    <Col span={24}>{children}</Col>
  </Wrapper>
);

export default Container;

import React, { useEffect, useState } from "react";
import { GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import { FlyToInterpolator } from "@deck.gl/core";

import BaseMap from "./index";
import iconAtlas from "../../assets/icons/pins.png";
import { iconMapping } from "../../constants";

const FormMap = ({ layers, value, location, features, onChange }) => {
  const { lng: longitude, lat: latitude, zoom } = location ?? {};
  const initialViewState = {
    longitude: longitude || 37.618423,
    latitude: latitude || 55.751244,
    zoom: zoom || 14,
    pitch: 0,
    bearing: 0,
  };
  const { lng, lat } = value ?? {};
  const data = value ? [{ coordinates: [lng, lat] }] : [];
  const [viewState, setViewState] = useState(initialViewState);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    latitude &&
      longitude &&
      setViewState((state) => ({
        ...state,
        latitude,
        longitude,
        zoom,
      }));
  }, [latitude, longitude, zoom]);

  const iconLayer = new IconLayer({
    id: "pin-layer",
    data,
    pickable: true,
    iconAtlas,
    iconMapping,
    getIcon: () => (isHovered ? "removeNavy" : "markerNavy"),
    getPosition: (d) => d?.coordinates,
    getSize: 96,
    autoHighlight: true,
    highlightColor: [255, 255, 255, 47],
    onHover: ({ object }) => setIsHovered(!!object),
    alphaCutoff: 0.5,
  });
  const territoryLayer = new GeoJsonLayer({
    id: `territory-layer`,
    data: features,
    pointRadiusMinPixels: 4,
    pointRadiusMaxPixels: 8,
    lineWidthMinPixels: 2,
    lineWidthMaxPixels: 8,
    getLineColor: [0, 102, 255, 191],
    getFillColor: [0, 102, 255, 31],
    stroked: true,
    filled: true,
  });

  const mapLayers = [
    ...(Array.isArray(layers) ? layers : []),
    territoryLayer,
    iconLayer,
  ];

  const onSelect = (_, option) => {
    const { latitude, longitude } = option ?? {};
    const viewState = {
      latitude,
      longitude,
      zoom: 12,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
    };

    latitude &&
      longitude &&
      setViewState((state) => ({ ...state, ...viewState }));
  };

  return (
    <BaseMap
      {...{ viewState, onSelect }}
      narrow
      geolocation
      defaultStyle="hybrid"
      onViewStateChange={({ viewState }) => setViewState(viewState)}
      layers={mapLayers}
      onClick={({ lngLat, object }) => {
        const [lng, lat] = lngLat;

        onChange && onChange(object ? undefined : { lng, lat, zoom });
      }}
    />
  );
};

export default FormMap;

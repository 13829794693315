import React from "react";
import { Rate } from "antd";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  span > span {
    margin-right: 1rem;
  }
`;

const Rating = ({ options, max, value: values, onChange }) => {
  const handleChange = (data) => {
    const index =
      values?.findIndex(({ answer: a }) => a === data?.answer) ?? -1;
    const { [index]: current, ...rest } = values ?? [];
    const newValues = [
      ...(index !== -1 ? Object.values(rest) : values || []),
      data,
    ];

    onChange(newValues);
  };
  const handleValue = (value) =>
    values?.length &&
    values.filter(({ answer }) => answer === value)?.[0]?.rate;

  return (
    <Container>
      {options?.map(({ name: text, value: answer }, i) => (
        <span key={i}>
          <span>{text}</span>
          <Rate
            count={max}
            value={handleValue(answer)}
            onChange={(rate) => handleChange({ answer, text, rate })}
          />
        </span>
      ))}
    </Container>
  );
};

export default Rating;

import React, { useState } from "react";
import { Form, Modal, Upload, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import styled from "styled-components/macro";

const Container = styled.div`
  .ant-upload {
    &-list-picture-card-container,
    &-select-picture-card {
      width: 8rem;
      height: 8rem;
    }
  }
`;
const AddFile = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0.5rem;

  .anticon {
    margin: 0.5rem;
  }
`;

const PhotoUpload = (props) => {
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  });
  const { previewVisible, previewImage, previewTitle, fileList } = state;

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleError = (error) => message.error(error, 5);

  const handleCheck = (file) => {
    const isJpgPngGif =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgPngGif) {
      handleError("Вы можете загрузить только JPG/PNG/GIF файл!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      handleError(`Размер изображения ${file.name} превышает 10 Мб!`);
    }
    return isJpgPngGif && isLt2M;
  };

  const handleFileList = (fileList) => {
    if (fileList.length > 5) {
      handleError("Можно загрузить не более 5 изображений!");
    }

    return fileList.filter((file) => handleCheck(file)).slice(-5);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return handleFileList(e);
    }

    return e && handleFileList(e.fileList);
  };

  const handleChange = ({ fileList }) => {
    setState((state) => ({
      ...state,
      fileList: [...fileList],
    }));
  };

  const handleCancel = () =>
    setState((state) => ({ ...state, previewVisible: false }));

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState((state) => ({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    }));
  };

  return (
    <Container>
      <Form.Item
        {...props}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        noStyle
      >
        <Upload
          {...{ customRequest }}
          onChange={handleChange}
          listType="picture-card"
          fileList={[...fileList]}
          defaultFileList={[...fileList]}
          previewFile={(file) => getBase64(file)}
          onPreview={handlePreview}
          accept="image/jpeg, image/png, image/gif"
          multiple
        >
          {fileList.length < 5 && (
            <AddFile>
              <PlusCircleOutlined />
              Прикрепите фото
            </AddFile>
          )}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Container>
  );
};

export default PhotoUpload;

import { createGlobalStyle } from "styled-components/macro";

import colors from "./colors";
import breakpoints from "./breakpoints";

const {
  primary,
  primaryDark,
  white,
  black,
  greyLight,
  greyLighter,
  greyLighterAlt,
  grey,
  greyDark,
  greyDarker,
  primaryLight,
  primaryLighter,
} = colors;
const { sm } = breakpoints;

const GlobalStyle = createGlobalStyle`
  :root { 
    --color-primary: ${primary};
    --color-primary-dark: ${primaryDark};
    --color-primary-light: ${primaryLight};
    --color-primary-lighter: ${primaryLighter};
    --color-white: ${white};
    --color-black: ${black};
    --color-grey-light: ${greyLight};
    --color-grey-lighter: ${greyLighter};
    --color-grey-lighter-alt: ${greyLighterAlt};
    --color-grey: ${grey};
    --color-grey-dark: ${greyDark};
    --color-grey-darker: ${greyDarker};
  }
  
  html, body, #root, .ant-layout {
    height: 100%;
  }
  
  body, input, textarea {
    font-weight: 300;
    letter-spacing: 0.02em;
  }
  
  .ant{
    &-layout{
      &-content {
        display: flex;
        flex-direction: column;
        min-height: fit-content;
      }
    
      &-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        padding: 0;
        width: 100%;
      }
    }
  
    &-btn,
    &-radio-button-wrapper {
      font-size: 0.875rem;
    }
    
    &-select-item {
      &, &-empty {
        font-weight: 300;
      }
    }
    
    @media screen and (max-width: ${breakpoints.sm}px) {
      &-list-pagination{
        text-align: left;
    
        .ant-pagination {
          display: flex;
          justify-content: space-between;
          
          ::after,
          ::before {
            display: none;
          }
        }
      }
    }
    
    &-modal-confirm-info .ant-modal-close {
      display: block;
    }
    
    &-form-item {
      &-label > label {
        display: inline-block;
        max-width: 100%;
        font-weight: 500;
        overflow-wrap: break-word;
        
        .ant-form-item-optional {
          font-weight: 400;
        }
      }
      
      &-extra {
        order: -1;
        padding-bottom: 1rem;
      }
      
      
      @media screen and (min-width: 576px) {
        input.ant-input {
          max-width: 20rem;
        }
      }
    }
    
    &-space {
      width: 100%;
    }
    
    &-steps-item-container {
      padding-bottom: 1rem;
    }
    
    &-result &-space {
      width: auto;
    }
    
    &-radio-wrapper {
      white-space: normal;
    }
    
    &-avatar-string {
      font-weight: 400;
      line-height: 1.9;
      margin-left: -0.0625rem;
    }
       
    &-typography {
      :not(.ant-typography-secondary) strong {
        color: rgba(0, 0, 0, 0.75);
      }
      
      ul {
        list-style-type: disc;
      }
    }
    
    &-image-preview{
      &-operations {
        padding: 0 2rem;
        line-height: 2.5rem;
        top: auto;
        bottom: 0;
      }
     
      &-img {
        padding: 6rem 2rem;
      }
    }    
  }
  
  h2.ant-typography, .ant-typography h2 {
    margin-bottom: 2rem; 
  }
  
  @media screen and (max-width: ${sm}px) {
    h1.ant-typography, .ant-typography h1 {
      font-size: 2.5rem; 
    } 
    
    h2.ant-typography, .ant-typography h2 {
      margin-bottom: 1.5rem; 
      font-size: 2rem; 
    }    
  }
`;

export default GlobalStyle;

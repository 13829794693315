import React from "react";
import styled from "styled-components";
import { Radio } from "antd";

import { mediaRules } from "../../theme/breakpoints";

const { mediaSm } = mediaRules;

const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    height: 2.5rem;
    line-height: 2.375rem;

    ${mediaSm} {
      padding: 0 0.6875rem;
      width: 100%;
      text-align: center;
    }
  }

  ${mediaSm} {
    display: flex;
    width: 100%;
  }
`;

const StyleControl = ({ options, onChange, defaultValue }) => (
  <RadioGroup
    {...{ defaultValue }}
    buttonStyle="solid"
    size="medium"
    onChange={(e) => onChange(e?.target?.value)}
  >
    {options?.map(({ label, value }, i) => (
      <Radio.Button key={i} {...{ value }}>
        {label}
      </Radio.Button>
    ))}
  </RadioGroup>
);

export default StyleControl;

import React from "react";
import styled from "styled-components/macro";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Spinner = styled(Spin)`
  .ant {
    &icon {
      font-size: 2rem;
    }

    &-spin {
      color: var(--color-primary);
    }
  }

  .ant-spin-nested-loading > div > & {
    max-height: 100%;
  }
`;

const Loader = props => (
  <Spinner indicator={<LoadingOutlined spin />} {...props} />
);

export default Loader;

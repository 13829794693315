import React from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";

import { handleForm, handleRules } from "../../utils";

const Page = ({ fields }) => {
  const { page } = useSelector(({ app }) => app);
  const { location: loc } = { ...page };

  return (
    <>
      {fields?.map((field, i) => {
        const {
          text: label,
          description: extra,
          node: name,
          uischema,
          schema,
          is_required: required,
        } = { ...field };
        const { uiwidget, uipollingtype } = { ...uischema };
        const { is_multiple } = { ...schema };

        return (
          <Form.Item
            {...{ label, name, extra }}
            key={i}
            rules={handleRules(uiwidget, required, schema)}
            messageVariables={
              (uiwidget === "checkbox" ||
                (uiwidget === "polling" && is_multiple)) && {
                label: "варианты",
                ruleLabel: "вариантов",
              }
            }
            style={{
              maxWidth:
                uiwidget === "polling" && uipollingtype === 2
                  ? "100%"
                  : "35rem",
            }}
          >
            {handleForm(field, loc)}
          </Form.Item>
        );
      })}
    </>
  );
};

export default Page;

/* eslint-disable no-template-curly-in-string */

import React, { useEffect, useState } from "react";
import { Layout, Button, Col, Empty, Form, Row, Space, Tabs } from "antd";
import styled from "styled-components/macro";
import { v4 as uuidv4 } from "uuid";

import Page from "./page";
import StepsSidebar from "./steps-sidebar";
import { handleAttachment, isChecked } from "../../utils";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const ContentContainer = styled(Content)`
  .ant-layout-content {
    overflow-x: visible;
  }
`;
const SidebarContainer = styled(Sider)`
  .ant-layout-sider {
    margin-right: 1.5rem;

    &-zero-width {
      margin-right: 0;
    }
  }
`;
const StyledTabs = styled(Tabs)`
  overflow: visible;
`;
const PageTitle = styled.h3`
  margin: 0 0 3rem;
  padding-bottom: 1rem;
  max-width: 45rem;
  border-bottom: 0.0625rem solid var(--color-grey);
`;
const Wizard = ({ data, platformId: platform_id, onSubmit }) => {
  const [form] = Form.useForm();
  const {
    id,
    name: bot_name,
    nodes = [],
    sections = [],
    is_anonymous = true,
  } = { ...data };
  const localId = localStorage.getItem("cwd-user-id");

  useEffect(() => {
    const uuidTemplate = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    is_anonymous &&
      (!localId || !uuidTemplate.test(localId)) &&
      localStorage.setItem("cwd-user-id", uuidv4());
  }, [is_anonymous, localId]);

  useEffect(() => form.resetFields(), [form, nodes]);

  const steps = sections
    .filter(({ uischema }) => uischema?.uiorder?.length > 0)
    .map(({ lead, uischema }, i) => {
      const { uiorder } = { ...uischema };
      const fields = nodes
        .filter(({ node }) => uiorder?.includes(node))
        .sort((a, b) => uiorder.indexOf(a.node) - uiorder.indexOf(b.node));

      return {
        title: `Шаг ${i + 1}`,
        lead,
        fields,
      };
    });

  const handleInitialValues = (radioInputs) => {
    const radioArray = radioInputs
      .filter(({ uischema, schema }) => {
        const { uiwidget } = { ...uischema };
        const { minimum } = { ...schema };
        const is_multiple = minimum > 1;

        return uiwidget === "radio" || (uiwidget === "polling" && !is_multiple);
      })
      .map(({ node, answers }) => ({ [node]: answers?.[0]?.value }));

    return Object.assign({}, ...radioArray);
  };

  const [current, setCurrent] = useState(0);
  const validateMessages = {
    required: "Обязательное поле!",
    array: {
      min: "Выберите не менее ${min} ${ruleLabel}",
      max: "Выберите не более ${max} ${ruleLabel}",
      range: "Выберите от ${min} до ${max} ${ruleLabel}",
    },
  };

  const handleClick = (i) => {
    setCurrent((current) => current + i);
    window.scrollTo(0, 0);
  };
  const handleFormData = (data) => {
    const { name, surname, email, ...rest } = { ...data };
    const user = {
      id: email || localId,
      username:
        name && surname ? `${name} ${surname}` : name || surname || localId,
      ...(name ? { first_name: name } : {}),
      ...(surname ? { last_name: surname } : {}),
      email: email || localId,
    };
    const bot = {
      id,
      name: bot_name,
      platform_id,
    };
    const filteredKeys = Object.keys(rest).filter((key) => {
      const [nodeKey, otherKey] = key.split("_");
      const isOtherValue = isChecked(data[nodeKey], otherKey);

      return data[key] && (otherKey ? isOtherValue : true);
    });
    const payload = filteredKeys?.map((key) => {
      const [nodeKey, otherKey] = key.split("_");
      const filteredNodes = nodes.filter(({ node }) => node === nodeKey);
      const [{ node, type: nodeType, tags }] =
        filteredNodes?.length > 0 ? filteredNodes : [{}];
      const type = otherKey ? "plain" : nodeType;
      const ordering =
        filteredKeys
          .sort((a, b) => a.includes(b) - b.includes(a))
          .indexOf(key) + 1;
      const params = otherKey ? ["variant", otherKey] : [type, data[nodeKey]];

      return {
        ordering,
        ts: Date.now(),
        is_answer: true,
        text: type === "plain" && data[key] ? data[key] : "",
        attachment: data[key] ? handleAttachment(...params) : {},
        node,
        type,
        tags,
      };
    });

    return {
      ts: Date.now(),
      bot,
      user,
      payload,
    };
  };
  const onFinish = (data) => onSubmit(handleFormData(data));
  const validateFields = (fields, callback) => {
    form
      .validateFields(fields)
      .then((values) => values && callback())
      .catch((error) => error);
  };
  const currentFields = steps?.[current]?.fields?.map(({ node }) => node);
  const hasSidebar = steps?.length > 1;
  const { title, lead } = { ...steps?.[current] };

  return (
    <Layout>
      {hasSidebar && (
        <SidebarContainer
          breakpoint="sm"
          collapsedWidth="0"
          width={160}
          trigger={null}
          theme="light"
        >
          <StepsSidebar {...{ steps, current }} />
        </SidebarContainer>
      )}
      <ContentContainer>
        <Row>
          <Col span={24}>
            {data && (
              <Space direction="vertical" size={24}>
                {steps?.length > 0 ? (
                  <Form
                    id="survey"
                    layout="vertical"
                    requiredMark="optional"
                    initialValues={nodes && handleInitialValues(nodes)}
                    validateTrigger="onSubmit"
                    {...{ form, onFinish, validateMessages }}
                  >
                    <StyledTabs
                      activeKey={current.toString()}
                      animated={false}
                      defaultActiveKey={1}
                      renderTabBar={() =>
                        lead || steps?.length > 1 ? (
                          <PageTitle>{lead || title}</PageTitle>
                        ) : null
                      }
                    >
                      {steps?.map(({ title, fields }, i) => (
                        <TabPane key={i} tab={title} forceRender>
                          <Page {...{ fields }} />
                        </TabPane>
                      ))}
                    </StyledTabs>
                  </Form>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Нет полей"
                  />
                )}
                <Space size={16}>
                  {current > 0 && (
                    <Button size="large" onClick={() => handleClick(-1)}>
                      Назад
                    </Button>
                  )}
                  {current < steps.length - 1 && (
                    <Button
                      size="large"
                      type="primary"
                      onClick={() =>
                        validateFields(currentFields, () => handleClick(1))
                      }
                    >
                      Вперёд
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      form="survey"
                    >
                      Отправить
                    </Button>
                  )}
                </Space>
              </Space>
            )}
          </Col>
        </Row>
      </ContentContainer>
    </Layout>
  );
};

export default Wizard;

import React from "react";
import { useDispatch } from "react-redux";
import { message, Modal } from "antd";
import ym from "react-yandex-metrika";

import SocialLogin from "../components/login/social-login";
import { setUserId } from "../actions/auth";
import { getTokenIds } from "./index";
import { getUserToken, tokenKey } from "../clients/gql-client";

const { info } = Modal;

const useLogin = () => {
  const dispatch = useDispatch();

  return (callback, description) => {
    const token = localStorage.getItem("cwd-user-token");

    if (token) {
      callback();
    } else {
      const onClose = () => modal.destroy();
      const onLogin = async (payload, setLoading) => {
        const { provider, accessToken, session, callback } = { ...payload };
        const token = localStorage.getItem(tokenKey);
        const { projectId, landingId } = {
          ...getTokenIds(token),
        };
        const authError = () => message.error("Ошибка авторизации");

        if (provider && (accessToken || session)) {
          try {
            const token = await getUserToken(
              projectId,
              landingId,
              provider,
              accessToken,
              session
            );

            if (token) {
              localStorage.setItem("cwd-user-token", token);
              ym("reachGoal", `login-${provider}`);

              const { userId } = {
                ...getTokenIds(token),
              };

              if (userId) {
                dispatch(setUserId(userId))
                  .then(() => {
                    onClose();
                    callback && callback(userId);
                  })
                  .catch(() => authError());
              }
            } else {
              !token && authError();

              setLoading(false);
            }
          } catch (error) {
            message.error(error);

            setLoading(false);
          }
        }
      };

      const modal = info({
        title: "Авторизация",
        content: <SocialLogin {...{ onLogin, callback, description }} />,
        closable: true,
        footer: null,
        width: 320,
        icon: null,
        okButtonProps: { style: { display: "none" } },
      });

      return modal;
    }
  };
};

export default useLogin;

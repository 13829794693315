import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Result, Button, Typography } from "antd";

import Container from "../components/layout/container";

const { Title } = Typography;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
  height: 100%;
`;

const NotFound = ({ children }) => (
  <Container>
    <Wrapper>
      <Result
        status="warning"
        title={<Title level={3}>404</Title>}
        subTitle="К сожалению, страница не существует."
        extra={
          children || (
            <Link
              to="/"
              component={({ children, href }) => (
                <Button type="primary" {...{ children, href }} />
              )}
            >
              Домой
            </Link>
          )
        }
      />
    </Wrapper>
  </Container>
);

export default NotFound;

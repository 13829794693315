export const NAVY = "Navy";
export const MAGENTA = "Magenta";
export const ROSE = "Rose";
export const SALMON = "Salmon";
export const GOLD = "Gold";
export const MEADOW = "Meadow";

const iconColors = [NAVY, MAGENTA, ROSE, SALMON, GOLD, MEADOW];
const dimensions = {
  width: 512,
  height: 512,
};

const handleIconMapping = (name, y) => ({
  [`marker${name || ""}`]: { x: 0, y, ...dimensions },
  [`remove${name || ""}`]: { x: 512, y, ...dimensions },
});

export const iconMapping = iconColors
  .map((icon, i) => handleIconMapping(icon, i * 512))
  ?.reduce((acc, val) => ({ ...acc, ...val }), {});

export const initialViewState = {
  latitude: 57.988715,
  longitude: 33.775243,
  zoom: 5.75,
  pitch: 0,
  bearing: 0,
};

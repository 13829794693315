import { gql } from "@apollo/client";

const AUTH_QUERY = gql`
  query tokenQuery($slug: String!) {
    token(input: { slug: $slug })
      @rest(type: "Token", path: "/project/auth", method: "POST") {
      project_id
      landing_id
      access_token
    }
  }
`;

const USER_TOKEN_QUERY = gql`
  query userTokenQuery(
    $projectId: String!
    $landingId: String!
    $provider: String!
    $accessToken: String!
    $session: Session
  ) {
    userToken(
      provider: $provider
      input: {
        project_id: $projectId
        landing_id: $landingId
        access_token: $accessToken
        session: $session
      }
    ) @rest(type: "UserToken", path: "/oauth/{args.provider}", method: "POST") {
      access_token
    }
  }
`;

const SURVEY_QUERY = gql`
  query surveyQuery($id: String!) {
    dialog(id: $id)
      @rest(type: "Dialog", endpoint: "dialog", path: "/bots/{args.id}") {
      bot
    }
  }
`;

const RATINGS_AGGREGATE_FRAGMENT = gql`
  fragment ratingsAggregateFragment on dialog_chat_idea_sat {
    ratings_aggregate {
      aggregate {
        count
      }
    }
  }
`;

const COMMENTS_AGGREGATE_FRAGMENT = gql`
  fragment commentsAggregateFragment on dialog_chat_idea_sat {
    comments_aggregate {
      aggregate {
        count
      }
    }
  }
`;

const COMMENTS_FRAGMENT = gql`
  fragment commentsFragment on dialog_chat_idea_sat {
    comments(order_by: { cr: asc_nulls_last }) {
      cr
      message(path: "text")
      user {
        first_name
        last_name
      }
    }
    ...commentsAggregateFragment
  }

  ${COMMENTS_AGGREGATE_FRAGMENT}
`;

const IDEA_FRAGMENT = gql`
  fragment ideaFragment on dialog_chat_idea_sat {
    cr
    date
    message
    location
    photos
    id
    category
    ratings {
      user_id
    }
    ...ratingsAggregateFragment
    ...commentsAggregateFragment
    chat_user {
      first_name
      last_name
      full_name
      id
      nickname
    }
  }

  ${RATINGS_AGGREGATE_FRAGMENT}
  ${COMMENTS_AGGREGATE_FRAGMENT}
`;

const IDEAS_QUERY = gql`
  query ideasQuery(
    $landingId: uuid
    $pageSize: Int = 1000
    $offset: Int = 0
    $categories: [String!]
    $orderBy: [dialog_chat_idea_sat_order_by!]
  ) {
    ideas(
      limit: $pageSize
      offset: $offset
      order_by: $orderBy
      where: {
        chat: { bot: { landing_link: { landing_id: { _eq: $landingId } } } }
        category: { _in: $categories }
      }
    ) {
      ...ideaFragment
    }
    idea_aggregate(
      where: {
        chat: { bot: { landing_link: { landing_id: { _eq: $landingId } } } }
        category: { _in: $categories }
      }
    ) {
      aggregate {
        count
      }
    }
  }

  ${IDEA_FRAGMENT}
`;

const IDEA_QUERY = gql`
  query ideaQuery($id: bigint!) {
    idea(id: $id) {
      ...ideaFragment
      ...commentsFragment
    }
  }

  ${IDEA_FRAGMENT}
  ${COMMENTS_FRAGMENT}
`;

const USER_QUERY = gql`
  query ideaQuery($userId: uuid) {
    user: dialog_chat_user_sat(where: { id: { _eq: $userId } }) {
      first_name
      last_name
    }
  }
`;

const ADD_VOTE_MUTATION = gql`
  mutation addVoteMutation($id: Int!) {
    insert_idea_rating(object: { idea_id: $id }) {
      idea_id
      user_id
      idea {
        ...ratingsAggregateFragment
      }
    }
  }

  ${RATINGS_AGGREGATE_FRAGMENT}
`;

const DELETE_VOTE_MUTATION = gql`
  mutation deleteVoteMutation($id: Int!, $userId: uuid) {
    delete_idea_ratings(
      where: { idea_id: { _eq: $id }, user_id: { _eq: $userId } }
    ) {
      returning {
        idea_id
        user_id
        idea {
          ...ratingsAggregateFragment
        }
      }
    }
  }

  ${RATINGS_AGGREGATE_FRAGMENT}
`;

const POST_COMMENT_MUTATION = gql`
  mutation postCommentMutation($id: Int!, $text: String!) {
    make_comment(idea_id: $id, payload: { text: $text, type: "plain" }) {
      success
      user_id
      dialog_id
      idea {
        ...commentsFragment
      }
    }
  }

  ${COMMENTS_FRAGMENT}
`;

const ALL_CATEGORIES_QUERY = gql`
  query allCategoriesQuery {
    categories: dialog_idea_category {
      id
      name
      key
      img
      deprecated
    }
  }
`;

const CATEGORIES_QUERY = gql`
  query categoriesQuery($landingId: uuid) {
    ideas(
      where: {
        chat: { bot: { landing_link: { landing_id: { _eq: $landingId } } } }
      }
      distinct_on: category
      order_by: { category: asc_nulls_last }
    ) {
      category
    }
  }
`;

const PLACE_QUERY = gql`
  query placeQuery($query: String) {
    suggestions(
      input: {
        query: $query
        count: 10
        from_bound: { value: "city" }
        to_bound: { value: "settlement" }
      }
    ) @rest(type: "Suggestion", path: "/", method: "POST") {
      value
      data @type(name: "Place") {
        country
        region
        city
        geo_lat
        geo_lon
      }
    }
  }
`;

export {
  AUTH_QUERY,
  USER_TOKEN_QUERY,
  SURVEY_QUERY,
  IDEAS_QUERY,
  IDEA_QUERY,
  ALL_CATEGORIES_QUERY,
  CATEGORIES_QUERY,
  USER_QUERY,
  ADD_VOTE_MUTATION,
  DELETE_VOTE_MUTATION,
  POST_COMMENT_MUTATION,
  PLACE_QUERY,
};

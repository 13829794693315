/**
 * External Dependencies
 */
import daxios, { authAxios } from "../clients/directAxios";
import handleError from "../errors/handleError";
import Tokenizer from "../helpers/tokenizer";

import { ApiEndpoint } from "../config";

export const auth_loginError = function () {
  return {
    type: "AUTH_LOGIN_ERROR",
  };
};

const api = new ApiEndpoint("auth");

export const AUTH_LOGIN = "AUTH_LOGIN";
export const auth_login = (email, password) => {
  return function (dispatch, getState) {
    return daxios
      .post(api.endpoint("login"), {
        email,
        password,
      })
      .then((res) => {
        try {
          dispatch({
            type: AUTH_LOGIN,
            payload: {
              ...res.data,
              // ...Tokenizer.decode(res.data.access_token)
            },
          });

          // Tokenizer.store(res.data.access_token, res.data.refresh_token, email);
        } catch (e) {
          dispatch(auth_loginError);
          return Promise.reject(res);
        }

        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(auth_loginError);
        handleError(error, getState(), dispatch);
        return Promise.reject(error);
      });
  };
};

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const auth_logout = () => {
  return function (dispatch, getState) {
    const body = {
      type: AUTH_LOGOUT,
    };

    return authAxios(getState())
      .post(api.endpoint("logout"))
      .then((res) => {
        dispatch(body);
        Tokenizer.clear();
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(body);
        Tokenizer.clear();
        return Promise.reject(error);
      });
  };
};

export const AUTH_CHECK = "AUTH_CHECK";
export const auth_check = () => {
  return function (dispatch, getState) {
    return authAxios(getState())
      .get(api.endpoint("check"))
      .then((res) => {
        dispatch({
          type: AUTH_CHECK,
          payload: {
            ...res.data,
          },
        });

        return Promise.resolve(res.data);
      })
      .then((data) => {
        dispatch(auth_update(data));

        return Promise.resolve(data);
      })
      .catch((error) => {
        dispatch(auth_error(error));

        handleError(error, getState(), dispatch);
        return Promise.reject(error);
      });
  };
};

export const AUTH_UPDATE = "AUTH_UPDATE";
export const auth_update = (auth) => ({
  type: AUTH_UPDATE,
  payload: {
    ...auth,
  },
});

export const AUTH_ERROR = "AUTH_ERROR";
export const auth_error = (error) => ({
  type: AUTH_ERROR,
  error,
});

export const SET_USER_ID = "SET_USER_ID";
export const setUserId = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER_ID,
    payload,
  });

  return Promise.resolve();
};

import React from "react";
import styled from "styled-components/macro";
import { Typography } from "antd";
import Icon, { QuestionCircleFilled } from "@ant-design/icons";

import { ReactComponent as Instagram } from "../../assets/icons/logo-inst.svg";
import { ReactComponent as Telegram } from "../../assets/icons/logo-tg.svg";
import { ReactComponent as Facebook } from "../../assets/icons/logo-fb.svg";
import { ReactComponent as Odnoklassniki } from "../../assets/icons/logo-ok.svg";
import { ReactComponent as VKontakte } from "../../assets/icons/logo-vk.svg";

const { Link } = Typography;

const Container = styled.div`
  a.ant-typography,
  .ant-typography a {
    font-size: 2rem;
    color: inherit;
  }

  svg {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover svg {
    opacity: 0.65;
  }
`;

const platforms = {
  inst: {
    prefix: "https://www.instagram.com/",
    name: "Instagram",
    icon: Instagram,
  },
  telegram: {
    prefix: "https://www.t.me/",
    name: "Telegram",
    icon: Telegram,
  },
  facebook: {
    prefix: "https://www.facebook.com/",
    name: "Facebook",
    icon: Facebook,
  },
  ok: {
    prefix: "https://www.ok.ru/",
    name: "Odnoklassniki",
    icon: Odnoklassniki,
  },
  vkontakte: {
    prefix: "https://www.vk.com/",
    name: "VKontakte",
    icon: VKontakte,
  },
};

const SocialButton = ({ platform, href }) => {
  const { icon } = { ...platforms?.[platform] };

  return (
    <Container>
      <Link {...{ href }} target="_blank" rel="noopener noreferrer">
        <Icon component={icon || QuestionCircleFilled} />
      </Link>
    </Container>
  );
};

export default SocialButton;

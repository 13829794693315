import React, { useState } from "react";
import { Form, Slider } from "antd";
import styled from "styled-components/macro";

const Container = styled.div`
  .ant-slider-mark {
    top: 1.25rem;
  }
`;

const Range = ({ name, type, min, max }) => {
  const [value, setValue] = useState(undefined);

  const handleMark = (value) => ({ [value]: value });
  const handleType = (value, type) =>
    value != null
      ? type === "range"
        ? Object.assign({}, ...value.map((v) => handleMark(v)))
        : handleMark(value)
      : {};

  const marks = {
    ...handleMark(min),
    ...handleType(value, type),
    ...handleMark(max),
  };

  return (
    <Container>
      <Form.Item {...{ name }} noStyle>
        <Slider
          {...{ min, max, marks }}
          range={type === "range"}
          included={false}
          tooltipVisible={false}
          onChange={(value) => setValue(value)}
        />
      </Form.Item>
    </Container>
  );
};

export default Range;

/**
 * External Dependencies
 */
import {
  APP_DATA,
  DIALOG_DATA,
  BOT_DIALOG_DATA,
  GET_POP_IDEAS,
  IDEAS_DATA,
  POST_IDEA,
  SET_CATEGORY,
  GET_MAP_IDEAS,
  GET_IDEA,
  GET_CAT_IDEAS,
  GET_IDEA_CATEGORIES,
} from "../actions/app";
import { makeViewState, ideasToGeojson } from "../utils";
import { SET_USER_ID } from "../actions/auth";

const INITIAL_APP_STATE = {
  userId: null,
  projectId: null,
  landingId: null,
  dialog: {},
  page: {},
  ideas: {
    ideas: [],
    page: 0,
    total: 0,
  },
  popIdeas: {},
  catIdeas: {},
  sentIdea: {},
  currentIdea: {},
  categories: [],
  currentCategory: 0,
  initialViewState: {},
  mapData: null,
  ideaCategories: [],
};

export default function (state = INITIAL_APP_STATE, action) {
  switch (action.type) {
    case GET_IDEA:
      return {
        ...state,
        currentIdea: action.payload,
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case GET_IDEA_CATEGORIES:
      return {
        ...state,
        ideaCategories: action.payload,
      };
    case APP_DATA:
      return {
        ...state,
        projectId: action.payload.project_id,
        landingId: action.payload.id,
        page: action.payload.data,
        tools: action.payload.tools,
        typeId: action.payload.type_id,
        categories: state.ideaCategories.filter((item) =>
          action.payload?.data?.categories?.includes(item.id)
        ),
        initialViewState: makeViewState(action.payload.data.location.map),
      };
    case BOT_DIALOG_DATA:
      return {
        ...state,
        dialog: [action.payload.bot],
      };
    case DIALOG_DATA:
      return {
        ...state,
        dialog: action.payload.bots,
      };
    case IDEAS_DATA:
      return {
        ...state,
        ideas: action.payload,
        showCatalog: action.payload.ideas.length > 0,
      };
    case GET_MAP_IDEAS:
      return {
        ...state,
        mapData: ideasToGeojson(action.payload.ideas),
      };
    case GET_POP_IDEAS:
      return {
        ...state,
        popIdeas: action.payload,
        showCatalog: action.payload.ideas.length > 0,
      };
    case GET_CAT_IDEAS:
      return {
        ...state,
        catIdeas: action.payload,
      };
    case POST_IDEA:
      return {
        ...state,
        sentIdea: action.payload,
      };
    case SET_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload,
      };
    default:
      return state;
  }
}

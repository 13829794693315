import React from "react";
import { Button, Grid, Space } from "antd";
import { useHistory } from "react-router-dom";

const { useBreakpoint } = Grid;

const ButtonGroup = ({ buttons, breakpoint }) => {
  const { push } = useHistory();
  const { sm } = useBreakpoint();
  const isMobile = breakpoint || !sm;

  return (
    <Space size={16} direction={isMobile ? "vertical" : "horizontal"}>
      {buttons?.map(({ name, type, to }, i) => (
        <Button
          {...{ type }}
          key={i}
          size="large"
          block={isMobile}
          onClick={() => push(to)}
        >
          {name}
        </Button>
      ))}
    </Space>
  );
};

export default ButtonGroup;

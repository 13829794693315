import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { ConfigProvider, Layout, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ru from "antd/es/locale/ru_RU";
import moment from "moment";
import "moment/locale/ru";

import client from "../clients/gql-client";
import GlobalStyle from "../theme/global-style";
import ScrollTop from "../components/layout/scroll-top";
import Main from "./main";
import Survey from "./survey";
import Header from "../components/layout/header";
import Footer from "../components/layout/footer";
import { getAppData } from "../actions/app";
import NotFound from "./404";
import { getTokenIds } from "../utils";
import { setUserId } from "../actions/auth";
import { project } from "../config";

export const routes = [
  { path: "/", component: Main, exact: true },
  {
    path: `/:slug(stress-survey|well-survey)`,
    component: Survey,
    exact: true,
  },
  { path: "*", component: NotFound, exact: false },
];

const { Link } = Typography;

const App = () => {
  const dispatch = useDispatch();

  const { Form, Image } = { ...ru };
  const locale = {
    ...ru,
    Form: { ...Form, optional: "(опционально)" },
    Image: {
      ...Image,
      preview: "Просмотр",
    },
  };
  const [notFound, setNotFound] = useState(false);

  moment.locale("ru");
  Spin.setDefaultIndicator(<LoadingOutlined />);

  useEffect(() => {
    dispatch(getAppData(project))
      .then((r) => r && setNotFound(false))
      .catch((e) => e && setNotFound(true));
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("cwd-user-token");
    const { userId } = {
      ...getTokenIds(token),
    };

    userId && dispatch(setUserId(userId));
  }, [dispatch]);

  return (
    <ApolloProvider {...{ client }}>
      <ConfigProvider {...{ locale }}>
        <GlobalStyle />
        <ScrollTop />
        <Layout>
          <Layout.Header>
            <Route path="/:page?">
              <Header {...{ notFound }} />
            </Route>
          </Layout.Header>
          <Layout.Content>
            {notFound ? (
              <NotFound>
                <Link href="https://чего-хочет-город.рф/">
                  Чего-хочет-город.рф
                </Link>
              </NotFound>
            ) : (
              <Switch>
                {routes.map((route, i) => (
                  <Route key={i} {...route} />
                ))}
              </Switch>
            )}
          </Layout.Content>
          <Layout.Footer style={{ padding: 0 }}>
            <Route path="/:page?">
              <Footer {...{ notFound }} />
            </Route>
          </Layout.Footer>
        </Layout>
      </ConfigProvider>
    </ApolloProvider>
  );
};

export default App;

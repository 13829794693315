import React, { useState } from "react";
import { Button, message, Space, Spin, Typography } from "antd";
import { FaFacebookF, FaGoogle, FaVk } from "react-icons/fa";
import styled from "styled-components/macro";
import VKLogin from "./vk-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

import privacy from "../../assets/pdf/privacy_policy_ru.pdf";
import terms from "../../assets/pdf/terms_of_use_ru.pdf";

const vkClientId = process.env.REACT_APP_VK_CLIENT_ID;
const fbClientId = process.env.REACT_APP_FB_CLIENT_ID;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const { Text, Link } = Typography;
const SocialButton = styled(({ bgColor, color, ...rest }) => (
  <Button {...rest} />
))`
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 0.75rem;

  &,
  :hover,
  :active,
  :focus {
    color: ${({ color }) => color || "var(--color-white)"};
    background: ${({ bgColor }) => bgColor};
    border-color: ${({ bgColor }) => bgColor};
  }

  :hover,
  :active,
  :focus {
    opacity: 0.88;
  }

  svg {
    font-size: 1.25rem;
    ${({ color }) => color === "var(--color-black)" && "fill: #f00"};
  }

  > span {
    margin-right: 1.25rem;
    margin-left: 0;
    padding-bottom: 0.125rem;
    width: 100%;
  }
`;
export const NoteContainer = styled.div`
  line-height: 1rem;
`;
export const Note = styled(Text)`
  font-size: 0.75rem;
  font-weight: 400;
`;

const SocialLogin = ({ callback, onLogin, description }) => {
  const [loading, setLoading] = useState(false);
  const [googleDisabled, setGoogleDisabled] = useState(false);

  const handleClick = (callback) => {
    setLoading(true);
    callback();
  };
  const handleToken = (payload) => onLogin(payload, setLoading);

  return (
    <Spin spinning={loading}>
      <Space direction="vertical" size={32}>
        <Text>
          {description ||
            "Чтобы проголосовать за идею или написать комментарий необходимо авторизоваться."}
        </Text>
        <Space direction="vertical">
          <VKLogin
            apiId={vkClientId}
            callback={({ session, status }) =>
              status !== "unknown" &&
              handleToken({ provider: "vk", session, callback })
            }
            settings={4194304}
            render={({ onClick, disabled }) => (
              <SocialButton
                {...{ disabled }}
                onClick={() => handleClick(onClick)}
                size="large"
                icon={<FaVk />}
                block
                bgColor="#4a76a8"
              >
                ВКонтакте
              </SocialButton>
            )}
          />
          <FacebookLogin
            appId={fbClientId}
            fields="name,email"
            language="ru_RU"
            disableMobileRedirect
            callback={({ accessToken }) =>
              handleToken({ accessToken, provider: "fb", callback })
            }
            onFailure={() => {
              setLoading(false);
            }}
            render={({ onClick, isSdkLoaded }) => (
              <SocialButton
                onClick={() => handleClick(onClick)}
                disabled={!isSdkLoaded}
                size="large"
                icon={<FaFacebookF />}
                block
                bgColor="#1877f2"
              >
                Facebook
              </SocialButton>
            )}
          />
          <GoogleLogin
            clientId={googleClientId}
            onSuccess={({ accessToken }) =>
              handleToken({
                accessToken,
                provider: "google",
                callback,
              })
            }
            onFailure={({ error }) => {
              if (error === "idpiframe_initialization_failed") {
                setGoogleDisabled(true);
              } else {
                if (error !== "popup_closed_by_user") {
                  message.error("Ошибка авторизации");
                }

                setGoogleDisabled(false);
                setLoading(false);
              }
            }}
            cookiePolicy="single_host_origin"
            render={({ onClick, disabled }) => (
              <Space direction="vertical">
                <SocialButton
                  onClick={() => handleClick(onClick)}
                  size="large"
                  icon={<FaGoogle />}
                  block
                  bgColor="#4285f4"
                  disabled={disabled || googleDisabled}
                >
                  Google
                </SocialButton>
              </Space>
            )}
          />
        </Space>
        <NoteContainer>
          <Note type="secondary">
            Авторизуясь, вы соглашаетесь с&nbsp;
            <Link href={terms} target="_blank" rel="noopener noreferrer">
              правилами пользования сайтом
            </Link>
            {" и "}
            <Link href={privacy} target="_blank" rel="noopener noreferrer">
              даете согласие на обработку персональных данных
            </Link>
            .
          </Note>
        </NoteContainer>
      </Space>
    </Spin>
  );
};

export default SocialLogin;

import React from "react";
import styled, { css } from "styled-components";
import {
  Card,
  Checkbox,
  Col,
  Image,
  Radio,
  Row,
  Space,
  Typography,
  Grid,
} from "antd";

import placeholderIcon from "../../assets/icons/placeholder.svg";

const { useBreakpoint } = Grid;
const { Text } = Typography;

const placeholder = <Image src={placeholderIcon} preview={false} />;
const maskStyles = css`
  &-mask-info {
    width: 1rem;
    font-size: 0;

    .anticon {
      margin-inline-end: 0;
      font-size: 1rem;
    }
  }
`;

const Container = styled.div`
  padding-top: 0.625rem;
  width: 100%;
  cursor: pointer;

  .ant {
    &-image {
      display: block;

      &-img {
        object-fit: cover;
      }

      &,
      &-img {
        height: 100%;
      }

      ${({ type }) => type === 1 && maskStyles};
    }

    &-card {
      flex-direction: column;

      &,
      &-body {
        display: flex;
        height: 100%;
      }

      &-body {
        align-items: center;
        padding: 1rem 1.5rem;

        div.ant-typography,
        .ant-typography p {
          margin-bottom: 0;
        }
      }
    }

    &-checkbox,
    &-radio {
      height: 100%;

      + span {
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
        padding-right: 0;
        width: 100%;
      }

      &-group {
        display: block;
      }

      &-wrapper {
        display: flex;
        align-items: center;
        min-height: 4rem;
      }
    }

    &-card-cover {
      margin-top: 0;
      border-bottom: 0.0625rem solid #f0f0f0;

      + .ant-card-body {
        padding: 1.5rem;

        .ant-checkbox,
        .ant-radio {
          &-wrapper {
            min-height: 3rem;
          }
        }
      }
    }
  }

  :not(.ant-card-cover) .ant-image {
    border: solid #f0f0f0;
    border-width: 0 0.0625rem;
  }
`;

const Polling = ({ type, options, isMultiple, value, onChange }) => {
  const Component = isMultiple ? Checkbox : Radio;
  const { sm, md } = useBreakpoint();
  const side = `${md ? 10 : sm ? 8 : 6}rem`;

  return (
    <Container {...{ type }}>
      <Component.Group {...{ value, onChange }}>
        <Row gutter={[20, 20]}>
          {options?.map(
            ({ name, description, attachment, value: optionValue }, i) => {
              const { url: src } = { ...attachment?.photo?.[0] };

              return (
                <Col
                  key={i}
                  span={24}
                  {...(type === 2 ? { sm: 12, lg: 8, xl: 6 } : {})}
                >
                  <Card
                    cover={
                      (type === 2 || (type === 1 && !sm)) && (
                        <Image
                          {...{ src, placeholder }}
                          width="100%"
                          height="14rem"
                          preview={!!src}
                        />
                      )
                    }
                  >
                    <Component value={optionValue}>
                      <Space size={24}>
                        {type === 1 && sm && (
                          <Image
                            {...{ src, placeholder }}
                            width={side}
                            height={side}
                            preview={!!src}
                          />
                        )}
                        <Space direction="vertical" size={8}>
                          {name && <Text>{name}</Text>}
                          {type !== 2 && description && (
                            <Text type="secondary">{description}</Text>
                          )}
                        </Space>
                      </Space>
                    </Component>
                  </Card>
                </Col>
              );
            }
          )}
        </Row>
      </Component.Group>
    </Container>
  );
};

export default Polling;

import React from "react";
import styled from "styled-components/macro";
import { Collapse } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

import FormMap from "../../map/form-map";

const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
  margin-bottom: 0.5rem;

  .ant-collapse {
    &-item {
      border: none;

      > .ant-collapse-content > .ant-collapse-content-box {
        padding: 0;
      }
    }

    &-header {
      display: flex;
      align-items: center;
    }

    &-extra {
      display: flex;
      align-items: center;
      margin-left: auto;
      height: 100%;
    }
  }
`;

const MapContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 22.5rem;
  overflow: hidden;
  background: #c4c4c4;
`;

const LocationSelect = (props) => (
  <StyledCollapse
    bordered={false}
    expandIcon={({ isActive }) =>
      isActive ? <MinusOutlined /> : <PlusOutlined />
    }
  >
    <Panel header="Прикрепите локацию" key="1" forceRender>
      <MapContainer>
        <FormMap {...props} />
      </MapContainer>
    </Panel>
  </StyledCollapse>
);

export default LocationSelect;

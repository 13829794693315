import React from "react";
import { Form, Input } from "antd";
import styled from "styled-components";

const Container = styled.div`
  display: inline-block;
  text-indent: 0;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const OtherField = ({ name, label, disabled, otherValue }) => (
  <Container>
    <Form.Item
      {...{ label }}
      name={`${name}_${otherValue}`}
      rules={[{ type: "string" }]}
    >
      <Input {...{ disabled }} size="small" placeholder="Введите ответ" />
    </Form.Item>
  </Container>
);

export default OtherField;

module.exports = {
  primary: "#3269ff",
  primaryDark: "#0050c8",
  white: "#fff",
  black: "#000",
  greyLight: "#cfcfcf",
  greyLighter: "#e8eef1",
  greyLighterAlt: "#f1f1f1",
  grey: "#c4c4c4",
  greyDark: "#6D757D",
  greyDarker: "#373C41",
  primaryLight: "#2E82FF",
  primaryLighter: "#E6F0FF",
  magenta: "#DA4DDA",
  rose: "#FF52A2",
  salmon: "#FF876E",
  gold: "#FFC355",
  meadow: "#1EC198",
};

import React from "react";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row, Space, Typography } from "antd";

import SocialButton from "../buttons/social-button";
import privacy from "../../assets/pdf/privacy_policy_ru.pdf";
import terms from "../../assets/pdf/terms_of_use_ru.pdf";
import { ReactComponent as LogoKB } from "../../assets/icons/logo-kb.svg";
import Container, { SectionContainer } from "./container";

const { Text, Link } = Typography;

const Wrapper = styled.div`
  display: ${({ hidden }) => (hidden ? "none" : "block")};
  background-color: var(--color-grey-lighter);
`;

const Footer = ({ notFound }) => {
  const { page: state } = useSelector(({ app }) => app);
  const { social } = { ...state };
  const { page } = useParams();

  return (
    <Wrapper hidden={!notFound && page === "map"}>
      <Container>
        <SectionContainer>
          <Row gutter={[24, 32]}>
            <Col span={24} sm={12} lg={8}>
              <Space direction="vertical" size={16}>
                <Space direction="vertical" size={8}>
                  <Text>Разработано на платформе </Text>
                  <Link href="https://чего-хочет-город.рф/">
                    Чего-хочет-город.рф
                  </Link>
                </Space>
                <Space direction="vertical" size={8}>
                  <Text>© ООО «КБ Стрелка», 2020</Text>
                  <Link href="https://strelka-kb.com/">
                    <LogoKB />
                  </Link>
                </Space>
              </Space>
            </Col>
            <Col span={24} sm={12} lg={8}>
              <Space direction="vertical" size={16}>
                <Text>Найдите нас в соцсетях</Text>
                <Space size={16}>
                  {social &&
                    Object?.keys(social)?.map((key, i) => (
                      <SocialButton key={i} platform={key} href={social[key]} />
                    ))}
                </Space>
              </Space>
            </Col>
            <Col span={24} sm={12} lg={8}>
              <Space direction="vertical">
                <Link href={terms} target="_blank" rel="noopener noreferrer">
                  Пользовательское соглашение
                </Link>
                <Link href={privacy} target="_blank" rel="noopener noreferrer">
                  Положение об обработке и&nbsp;защите персональных данных
                </Link>
              </Space>
            </Col>
          </Row>
        </SectionContainer>
      </Container>
    </Wrapper>
  );
};

export default Footer;

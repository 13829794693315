import { GeoJsonLayer } from "@deck.gl/layers";
import bbox from "@turf/bbox";
import { points } from "@turf/helpers";
import jwt from "jsonwebtoken";

import { pick } from "../helpers/fn";
import { initialViewState } from "../constants";
import { DatePicker, Input } from "antd";
import ControlGroup from "../components/wizard/control-group";
import Range from "../components/wizard/range";
import locale from "antd/es/date-picker/locale/ru_RU";
import PhotoUpload from "../components/feedback/idea-form/photo-upload";
import LocationSelect from "../components/feedback/map-inputs/location-select";
import Rating from "../components/wizard/rating";
import Polling from "../components/wizard/polling";
import React from "react";

const pluralize = (number, variant1, variant2, variant3) => {
  if (number % 10 === 1 && number % 100 !== 11) {
    return variant1;
  } else if (
    number % 10 >= 2 &&
    number % 10 <= 4 &&
    (number % 100 < 10 || number % 100 >= 20)
  ) {
    return variant2;
  } else {
    return variant3;
  }
};

export const getCurrentTime = () => {
  const today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const time = today.getHours() + ":" + today.getMinutes();
  return date + " " + time;
};

export const getCategories = (ids) => {
  const categories = {
    1: { name: "Велоинфраструктура", img: "bikes.png" },
    2: { name: "Гаражи и парковки", img: "parkings.png" },
    3: { name: "Городское озеленение", img: "urban-landscaping.png" },
    4: { name: "Детские площадки", img: "playgrounds.png" },
    5: { name: "Домашние животные", img: "pets.png" },
    6: { name: "Дороги", img: "roads.png" },
    7: { name: "Доступная среда", img: "accessability.png" },
    8: { name: "Другое", img: "other.png" },
    9: { name: "Зеленые пространства", img: "green-spaces.png" },
    10: {
      name: "Инженерная инфраструктура",
      img: "engineering-infrastructure.png",
    },
    11: { name: "Кафе и магазины", img: "shops-n-food.png" },
    12: { name: "Музеи", img: "museums.png" },
    13: { name: "Мусор и Загрязнение", img: "waste.png" },
    14: { name: "Навигация", img: "navigation.png" },
    15: { name: "Наследие", img: "heritage.png" },
    16: { name: "Образование", img: "education.png" },
    17: { name: "Освещение", img: "lighting.png" },
    18: { name: "Пешеходная инфраструктура", img: "pedestrian.png" },
    19: { name: "Площадки для отдыха", img: "recreation.png" },
    20: { name: "Реклама и брендинг", img: "branding.png" },
    21: { name: "Социальная инфраструктура", img: "social.png" },
    22: { name: "Спорт", img: "sports.png" },
    23: { name: "Строительство", img: "construction-sites.png" },
    24: { name: "Транспорт", img: "transport.png" },
    25: { name: "Туризм", img: "tourism.png" },
    26: { name: "Услуги и сервисы", img: "services.png" },
    27: { name: "Фасады", img: "facades.png" },
  };
  if (ids && Array.isArray(ids)) {
    let res = [];
    ids.forEach((e) => res.push(categories[e]));
    return res;
  }
};

export const makeViewState = (data) => {
  const { lng: longitude, lat: latitude, zoom } = { ...data };

  return {
    longitude,
    latitude,
    zoom,
    minZoom: 14,
    maxZoom: 17,
    pitch: 0,
    bearing: 0,
  };
};

export const ideaToGeojsonFeature = (data) => {
  return {
    type: "Feature",
    properties: {
      id: data.id,
      username: data.username,
      message: data.message,
      name: "Предложение от " + data.username,
      category: data.category,
      date: data.date,
    },
    geometry: {
      type: "Point",
      coordinates: [data.location.lng, data.location.lat],
    },
  };
};

export const ideasToGeojson = (data) => {
  return {
    type: "FeatureCollection",
    features: data
      .filter((item) => {
        return (
          pick("location.lng", item, 0) * pick("location.lat", item, 0) > 0
        );
      })
      .map((e) => ideaToGeojsonFeature(e)),
  };
};

export const handleViewPort = (
  coordinates = [],
  viewPort,
  padding = 64,
  minZoom = 10
) => {
  const [left, bottom, right, top] =
    bbox(
      coordinates?.type === "FeatureCollection"
        ? coordinates
        : points(coordinates)
    ) ?? [];
  const { latitude, longitude, zoom, pitch, bearing } =
    coordinates?.length > 0
      ? viewPort.fitBounds(
          [
            [bottom, left],
            [top, right],
          ],
          {
            padding,
          }
        )
      : initialViewState;

  return {
    latitude,
    longitude,
    zoom: zoom <= minZoom ? zoom : minZoom,
    pitch,
    bearing,
  };
};

export const createRouteLayer = (id, data) =>
  new GeoJsonLayer({
    id,
    data,
    pickable: false,
    getLineColor: [0, 102, 255],
    stroked: true,
    filled: false,
    extruded: false,
    visible: true,
    lineWidthMinPixels: 2,
    lineWidthMaxPixels: 8,
  });

export const getTokenIds = (token) => {
  const { hasura_claims } = {
    ...jwt.decode(token),
  };
  const {
    "x-hasura-project-id": projectId,
    "x-hasura-landing-id": landingId,
    "x-hasura-user-id": userId,
  } = {
    ...hasura_claims,
  };

  return { projectId, landingId, userId };
};

export const isTokenExpired = (token) => {
  const { exp } = { ...jwt.decode(token) };
  // Refresh the token a minute early to avoid latency issues
  return Date.now() >= exp * 1000 - 60000;
};

export const hasLikes = (userId, ratings) =>
  userId &&
  ratings?.length > 0 &&
  ratings?.some(({ user_id }) => user_id === userId);

export const handleUserName = (first, last) =>
  (first || "") + (first && last ? " " : "") + (last || "");

export const hasTool = (tools, type) =>
  tools?.map(({ type_id }) => type_id)?.includes(type);

const handleMinMax = (v, min, max) => {
  const value = Math.round(v * 100000) / 100000;

  return value < min ? min : value > max ? max : value;
};

export const handleViewState = (lng, lat, z) => {
  const longitude = handleMinMax(lng, -180, 180);
  const latitude = handleMinMax(lat, -90, 90);
  const zoom = handleMinMax(z, 2, 22);

  return { longitude, latitude, zoom, bearing: 0, pitch: 0 };
};

export const handleCategoryName = (categories, category) =>
  categories?.length &&
  categories.filter(({ id }) => parseInt(category) === id)?.[0]?.name;

export const isChecked = (a, b) => (Array.isArray(a) ? a.includes(b) : a === b);

export const handleTypeUrl = (type) => {
  switch (type) {
    case 3:
      return "poll";
    case 2:
      return "survey";
    case 1:
    default:
      return "suggest";
  }
};
export const handleTypeText = (type) => {
  switch (type) {
    case 3:
      return "Голосовать";
    case 2:
      return "Пройти опрос";
    case 1:
    default:
      return "Предложить идею";
  }
};
export const handleAttachment = (type, data) => {
  switch (type) {
    case "variant":
      return {
        variant: data,
      };
    case "variants":
    case "rating":
      return {
        variants: data,
      };
    case "location":
      const { lng, lat } = data ?? {};

      return {
        location: {
          lng,
          lat,
        },
      };
    case "photo":
      return {
        photo: data?.map(({ thumbUrl: url }) => ({ url })),
      };
    case "date":
      return {
        date: data?.format(),
      };
    case "value":
    case "range":
      return {
        value: data,
      };
    case "polling":
      return {
        variants: Array.isArray(data) ? data : [data],
      };
    default:
      return {};
  }
};
export const handleForm = (field, loc) => {
  const { node: name, uischema, answers: options, schema } = {
    ...field,
  };
  const { uiwidget: type, uipollingtype } = { ...uischema };
  const { minimum: min, maximum: max, is_multiple: isMultiple } = {
    ...schema,
  };
  const { map: location, features } = { ...loc };

  switch (type) {
    case "textarea":
      return <Input.TextArea autoSize={{ minRows: 3 }} />;
    case "checkbox":
    case "radio":
      return <ControlGroup {...{ name, type, options }} />;
    case "value":
    case "range":
      return <Range {...{ name, type, min, max }} />;
    case "date":
      return <DatePicker {...{ locale }} />;
    case "photos":
      return <PhotoUpload {...{ name }} />;
    case "map-selection":
      return <LocationSelect {...{ location, features }} />;
    case "rating":
      return <Rating {...{ options, max }} />;
    case "polling":
      return <Polling {...{ options, isMultiple }} type={uipollingtype} />;
    case "text":
    default:
      return <Input />;
  }
};
export const handleRules = (uiwidget, required, schema) => {
  const { type, minimum: min, maximum: max, is_multiple } = {
    ...schema,
  };

  switch (uiwidget) {
    case "email":
      return [
        {
          type: "email",
          required,
          message: "Введите корректный email!",
        },
      ];
    case "range":
      return [
        {
          type,
          required,
          ...(min != null ? { min } : {}),
          ...(max != null ? { max } : {}),
        },
      ];
    case "polling":
      return [
        { required: true },
        {
          type: is_multiple ? "array" : "string",
          ...(is_multiple ? { min: min || 1 } : {}),
          ...(is_multiple ? { max: max || 1 } : {}),
          ...(min === max
            ? {
                message: `Выберите ${max} ${pluralize(
                  max,
                  "вариант",
                  "варианта",
                  "вариантов"
                )}`,
              }
            : {}),
        },
      ];
    default:
      return [
        { type, required, ...(min ? { min } : {}), ...(max ? { max } : {}) },
      ];
  }
};

import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ page, pageTitle }) => {
  const { title, lead, photo } = page ?? {};
  const defaultTitle = "Чего хочет город";
  const siteTitle = title || defaultTitle;

  return (
    <Helmet titleTemplate={`${siteTitle} - %s`} defaultTitle={siteTitle}>
      {/* General tags */}
      <title>{pageTitle || ""}</title>
      <meta name="description" content={lead || siteTitle} />
      {/* OpenGraph tags */}
      {typeof window !== "undefined" && (
        <meta name="og:url" content={window.location.href} />
      )}
      <meta
        name="og:title"
        content={siteTitle + (pageTitle ? ` - ${pageTitle}` : "")}
      />
      <meta name="og:description" content={lead || siteTitle} />
      {photo && <meta name="og:image" content={photo} />}
      <meta name="og:type" content="website" />
    </Helmet>
  );
};

export default Meta;

import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components/macro";
import { Col, Row, Skeleton, Space, Typography } from "antd";

import Container from "../layout/container";

const { Title, Paragraph, Link } = Typography;

const Wrapper = styled(Space)`
  margin-top: 1rem;
`;

const Description = ({ title, content }) => (
  <Container>
    <Wrapper direction="vertical">
      <Title level={2}>{title}</Title>
      {content ? (
        <Row>
          <Col span={24} md={20} lg={16}>
            <Paragraph>
              <ReactMarkdown
                renderers={{
                  link: ({ children, ...props }) => (
                    <Link {...props} target="_blank" rel="noopener noreferrer">
                      {children || ""}
                    </Link>
                  ),
                }}
              >
                {content}
              </ReactMarkdown>
            </Paragraph>
          </Col>
        </Row>
      ) : (
        <Skeleton title={false} active />
      )}
    </Wrapper>
  </Container>
);

export default Description;

import React, { useState } from "react";
import DeckGL from "@deck.gl/react";
import { MapController } from "@deck.gl/core";
import ReactMapGL, {
  _MapContext as MapContext,
  NavigationControl,
  GeolocateControl,
} from "react-map-gl";
import styled from "styled-components/macro";

import { mediaRules } from "../../theme/breakpoints";
import StyleControl from "./style-control";
import geolocate from "../../assets/icons/geolocate.svg";
import geolocateBg from "../../assets/icons/geolocate-bg.svg";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const { mediaSm } = mediaRules;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 10rem;
  min-width: 10rem;

  .mapboxgl-user-location {
    &-dot {
      &,
      :before {
        background-color: var(--color-primary);
      }
    }

    &-accuracy-circle {
      background-color: rgba(50, 105, 255, 0.2);
    }
  }
`;

const ControlsContainer = styled(({ narrow, includeHeader, ...props }) => (
  <div {...props} />
))`
  position: absolute;
  top: ${({ includeHeader }) => (includeHeader ? "4rem" : 0)};
  bottom: 0;
  right: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: ${({ narrow }) => (narrow ? "1rem" : "2rem")};
  width: 100%;
  pointer-events: none;

  ${mediaSm} {
    padding: ${({ narrow }) => (narrow ? "0.75rem" : "1.25rem")};
  }

  > * {
    pointer-events: all;

    :not(:last-child) {
      margin-bottom: 1rem;
    }

    ${mediaSm} {
      width: 100%;
    }
  }
`;
const NavigationContainer = styled(({ loading, position, ...props }) => (
  <ControlsContainer {...props} />
))`
  justify-content: flex-end;

  > * {
    width: 2rem;

    ${mediaSm} {
      width: 2.5rem;
    }
  }

  .mapboxgl-ctrl {
    &-group {
      border-radius: 0;

      :not(:empty) {
        box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.12);
      }

      button {
        width: 2rem;
        height: 2rem;

        &.mapboxgl-ctrl-geolocate {
          .mapboxgl-ctrl-icon {
            background-size: 100%;
          }

          &-active .mapboxgl-ctrl-icon {
            background-image: url(${geolocate});
          }

          &-background .mapboxgl-ctrl-icon {
            background-image: url(${geolocateBg});
          }
        }

        ${mediaSm} {
          width: 2.5rem;
          height: 2.5rem;
        }

        :focus {
          border-radius: 0;
          box-shadow: 0 0 0.0625rem 0.0625rem var(--color-primary);
        }
      }
    }

    //&-compass-arrow {
    //  display: block;
    //  width: 100%;
    //  height: 100%;
    //  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E%3C/svg%3E");
    //  background-repeat: no-repeat;
    //  background-position: 50%;
    //}
  }
`;

const BaseMap = ({
  children,
  controller,
  onSelect,
  narrow,
  controls,
  styleControl = true,
  geolocation,
  includeHeader,
  defaultStyle = "scheme",
  ...props
}) => {
  const mapboxPrefix = "mapbox://styles/ipakhomov/";
  const styleOptions = {
    scheme: {
      label: "Схема",
      value: `${mapboxPrefix}ckmyumpn2262217qrd4kkleqg`,
    },
    satellite: {
      label: "Спутник",
      value: `${mapboxPrefix}ckmyuuw3t26mc17qpkd5rdb6p`,
    },
    hybrid: {
      label: "Гибрид",
      value: `${mapboxPrefix}ckmyuti3x267o17k13l6mnele`,
    },
  };
  const { scheme, [defaultStyle]: defStyle } = styleOptions;
  const { value: defaultValue } = { ...(defStyle || scheme) };
  const [mapStyle, setMapStyle] = useState(defaultValue);

  return (
    <Container>
      <ControlsContainer {...{ narrow, includeHeader }}>
        {styleControl && (
          <StyleControl
            {...{ defaultValue }}
            options={Object.values(styleOptions)}
            onChange={(style) => setMapStyle(style)}
          />
        )}
      </ControlsContainer>
      <DeckGL
        ContextProvider={MapContext.Provider}
        controller={controller || { type: MapController, scrollZoom: false }}
        {...props}
      >
        <NavigationContainer {...{ narrow, includeHeader }}>
          <NavigationControl
            showCompass={false}
            zoomInLabel="Приблизить"
            zoomOutLabel="Отдалить"
            style={{ position: "relative" }}
          />
          {geolocation && (
            <GeolocateControl
              label="Моё местоположение"
              disabledLabel="Местоположение недоступно"
              positionOptions={{ enableHighAccuracy: true }}
              style={{ position: "relative" }}
              trackUserLocation
            />
          )}
        </NavigationContainer>
        <ReactMapGL
          {...{ mapStyle }}
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
          attributionControl={false}
          preventStyleDiffing
        />
        {children}
      </DeckGL>
    </Container>
  );
};

export default BaseMap;

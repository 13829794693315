import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Result, Skeleton, Space } from "antd";
import { useQuery } from "@apollo/client";

import { postDialog } from "../actions/app";
import Meta from "../components/layout/meta";
import Wizard from "../components/wizard";
import Loader from "../components/layout/loader";
import ym from "react-yandex-metrika";
import Container, { PageContainer } from "../components/layout/container";
import useLogin from "../utils/use-login";
import { SURVEY_QUERY } from "../queries";
import { restClient as client } from "../clients/gql-client";

const Survey = () => {
  const { page, tools = [] } = useSelector(({ app }) => app);
  const { thanks } = { ...page };
  const dispatch = useDispatch();
  const { slug } = useParams();
  const withAuth = useLogin();
  const platformId = 1;
  const [isSuccess, setIsSuccess] = useState(false);
  const [err, setErr] = useState({ isError: false, message: "" });
  const { isError, message } = err;
  const [isLoading, setIsLoading] = useState(false);
  const { bot_id: id } = { ...tools.find(({ key }) => key === slug) };

  const { data, loading, error } = useQuery(SURVEY_QUERY, {
    client,
    variables: { id },
    skip: !id,
  });

  const { bot: dialog } = { ...data?.dialog };
  const { sections, nodes, type_id, is_anonymous } = { ...dialog };
  const isPolling = type_id === 3;

  useEffect(() => {
    setIsLoading(false);
    setIsSuccess(false);
  }, [slug]);

  useEffect(() => {
    if (error || (id && !loading && !dialog)) {
      setErr({ isError: true, message: "Опрос не найден!" });
    }
    if (dialog) {
      setErr({ isError: false, message: undefined });
    }
  }, [dialog, error, id, loading]);

  const authErrorMessage = "Чтобы проголосовать необходимо авторизоваться.";

  const conditionalAuth = (fn, condition, message) =>
    condition ? withAuth(fn, message) : fn();
  const handleUserData = (data, userId) => ({
    ...data,
    user: {
      id: userId,
      username: userId,
      email: userId,
    },
  });
  const handleSubmit = (data, userId) => {
    setIsLoading(true);
    dispatch(
      postDialog(isPolling && userId ? handleUserData(data, userId) : data)
    ).then(({ success }) => {
      if (success) {
        setIsSuccess(true);
        ym("reachGoal", isPolling ? "poll" : `survey${slug ? `-${slug}` : ""}`);
      }
    });
  };
  const onSubmit = (data) =>
    conditionalAuth(
      (token) => handleSubmit(data, token),
      isPolling,
      authErrorMessage
    );

  const metaSection = {
    ordering: 0,
    nodes: ["name", "surname", "email"],
    uischema: {
      uiorder: ["name", "surname", "email"],
      uiwidget: "page",
    },
  };
  const metaArray = [
    { text: "Имя", node: "name", uiwidget: "text" },
    { text: "Фамилия", node: "surname", uiwidget: "text" },
    { text: "Email", node: "email", uiwidget: "email" },
  ];
  const metaNodes = metaArray.map(({ text, node, uiwidget }) => ({
    tags: [node],
    text,
    node,
    type: "plain",
    schema: {
      type: "string",
    },
    uischema: {
      uiwidget,
    },
    is_required: true,
  }));
  const dialogWithMeta = {
    ...dialog,
    sections: [metaSection, ...(sections || [])],
    nodes: [...metaNodes, ...(nodes || [])],
  };

  return (
    <Container>
      <Meta title="Пройдите опрос" {...{ page }} />
      <PageContainer>
        {isSuccess ? (
          <Result
            status="success"
            title="Отправлено!"
            subTitle={thanks || "Спасибо за ваше участие!"}
            extra={[
              <Link to="/" key="home">
                <Button type="primary" size="large">
                  На главную
                </Button>
              </Link>,
            ]}
          />
        ) : isError ? (
          <Result
            status="error"
            title={message || "Ошибка"}
            extra={[
              <Link to="/" key="home">
                <Button type="primary" size="large">
                  На главную
                </Button>
              </Link>,
            ]}
          />
        ) : dialog ? (
          <Loader spinning={isLoading}>
            <Wizard
              data={isPolling || is_anonymous ? dialog : dialogWithMeta}
              {...{ platformId, onSubmit }}
            />
          </Loader>
        ) : (
          <Space direction="vertical" size={0} style={{ maxWidth: "35rem" }}>
            {[...Array(3).keys()].map((_, i) => (
              <Skeleton
                key={i}
                title={{ width: 240 }}
                paragraph={{ rows: 1, width: "100%" }}
                active
              />
            ))}
          </Space>
        )}
      </PageContainer>
    </Container>
  );
};

export default Survey;
